require("@rails/ujs").start()
import 'unpoly/dist/unpoly'
import hyperform from 'hyperform'
import Splide from '@splidejs/splide'
import SimpleLightbox from "simplelightbox"
import Cleave from 'cleave.js'
import * as FilePond from 'filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

require('cleave.js/dist/addons/cleave-phone.ch');

up.compiler('body', () => {
    document.querySelector('html').classList.remove('no-js')
})

up.compiler('.navbar:not(.-after-scroll)', (element) => {
    let scrollPos = window.scrollY
    const header = element
    const teaser = document.querySelector('.teaser')

    teaser?.classList?.remove('hidden')
    let heightForTeaser = (window.innerHeight / 2) + 60
    teaser?.classList?.add('hidden')

    const add_class_on_scroll = () => header.classList.add("-after-scroll")
    const remove_class_on_scroll = () => header.classList.remove("-after-scroll")

    const callback = () => {
        scrollPos = window.scrollY;

        if (scrollPos >= 10) {
            add_class_on_scroll()
        } else {
            remove_class_on_scroll()
        }
        if (scrollPos >= heightForTeaser) {
            teaser?.classList?.remove('hidden')
        } else {
            teaser?.classList?.add('hidden')
        }
    }

    window.addEventListener('scroll', callback);

    return () => window.removeEventListener('scroll', callback)
})

up.compiler('.js-teaser-trigger', (element) => {
    if (!window.matchMedia('(any-hover: hover)').matches) {
        element.addEventListener('click', (event) => {
            document.querySelector('.teaser-content').classList.add('-visible')
        })
    }
})

up.compiler('.js-teaser-close', (element) => {
    if (!window.matchMedia('(any-hover: hover)').matches) {
        element.addEventListener('click', (event) => {
            document.querySelector('.teaser-content').classList.remove('-visible')
        })
    }
})

up.compiler('.mobile-nav', (element) => {
    const mobileNav = element
    const menuButton = document.querySelector('.js-menu-button')

    menuButton.addEventListener('click', function () {
        this.classList.toggle('active')
        mobileNav.classList.toggle('translate-x-full')
    })

    const navItems = mobileNav.getElementsByClassName('nav-item dropdown')
    let i

    for (i = 0; i < navItems.length; i++) {
        navItems[i].addEventListener('click', function () {
            this.classList.toggle('-open')

            const dropdownMenu = this.querySelector('.dropdown-menu')
            const dropdownMenuHeight = dropdownMenu.scrollHeight

            if (dropdownMenu.style.maxHeight === dropdownMenuHeight + 'px') {
                dropdownMenu.style.maxHeight = '0'
            } else {
                dropdownMenu.style.maxHeight = dropdownMenuHeight + 'px'
            }
        })

        navItems[i].querySelector('.nav-link').addEventListener('click', (event) => {
            event.preventDefault()

            return false;
        })
    }

    return () => menuButton.removeEventListener('click')
})

up.compiler('#splide', (element) => {
    new Splide(element, {
        perPage: 3,
        perMove: 3,
        gap: '2.5rem',
        padding: {
            right: '1.75rem',
            left: '1.75rem',
        },
        breakpoints: {
            1024: {
                perPage: 2,
                perMove: 2,
            },
            640: {
                perPage: 1,
                perMove: 1,
            },
        },
    }).mount()

    const pagination = element.querySelector('.splide__pagination')
    const arrowPrev = element.querySelector('.splide__arrow--prev')

    window.addEventListener('load', function () {
        arrowPrev.style.marginRight = pagination.offsetWidth + 'px'
    })

    return () => window.removeEventListener('load')
})

up.compiler('.accordion', (element) => {
    let acc_btn = document.getElementsByClassName('js-accordion-button')
    let i

    for (i = 0; i < acc_btn.length; i++) {
        acc_btn[i].addEventListener('click', function () {
            this.classList.toggle('active');

            let panel = this.nextElementSibling;
            let panel_height = panel.scrollHeight
            if (panel.style.maxHeight === panel_height + 'px') {
                panel.style.maxHeight = '0'
            } else {
                panel.style.maxHeight = panel_height + 'px'
            }
        })
    }
})

up.compiler('.gallery-grid', () => {
    const lightbox = new SimpleLightbox('.gallery-item', {
        navText: [`<svg viewBox="0 0 25 43" width="22" height="44"><path id="Path" d="M0 0l19.8003465 19.8003465L0 39.600693" fill="none" fill-rule="evenodd" transform="rotate(180 11.400173 20.800347)" stroke="#5F8264" stroke-width="4"/></svg>`,
            `<svg viewBox="0 0 12.121 21.414" width="22" height="44"><path data-name="Pfad 6669" d="M.707.707l10 10-10 10" fill="none" stroke-miterlimit="10" stroke-width="2" stroke="#5F8264"/></svg>`],
        closeText: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" width="28" height="28"><g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><g id="Artboard" transform="translate(-5 -5)" stroke="#5F8264" stroke-width="4"><path id="Line-2" d="M8 8l28 28M36 8L8 36"/></g></g></svg>`,
        captionPosition: 'outside',
        widthRatio: 0.85,
        history: false,
    })
})


up.compiler('form', () => {
    hyperform.addTranslation("de", {
        TextTooLong: "Bitte kürzen Sie diesen Text auf maximal %l Zeichen (Sie verwenden derzeit %l Zeichen).",
        TextTooShort: "Bitte verwenden Sie zumindest %l Zeichen (Sie verwenden derzeit %l Zeichen).",
        ValueMissing: "Bitte füllen Sie dieses Feld aus.",
        RadioMissing: "Bitte wählen Sie eine dieser Optionen.",
        CheckboxMissing: "Bitte aktivieren Sie dieses Kontrollkästchen, wenn Sie fortfahren möchten.",
        InvalidEmail: "Bitte geben Sie eine E-Mail-Adresse ein.",
        PatternMismatch: "Bitte geben Sie eine E-Mail-Adresse ein.",
        "Please match the requested type.": "Bitte passen Sie die Eingabe dem geforderten Typ an.",
        "Please comply with all requirements.": "Bitte erfüllen Sie alle Anforderungen.",
        "Please lengthen this text to %l characters or more (you are currently using %l characters).": "Bitte verlängern Sie diesen Text auf mindestens %l Zeichen (Sie verwenden derzeit %l Zeichen).",
        "Please use the appropriate format.": "Bitte verwenden Sie das passende Format.",
        "Please enter a comma separated list of email addresses.": "Bitte geben Sie eine komma-getrennte Liste von E-Mail-Adressen an.",
        "Please select a file of the correct type.": "Bitte wählen Sie eine Datei vom korrekten Typ.",
        "Please select one or more files.": "Bitte wählen Sie eine oder mehrere Dateien.",
        "any value": "jeder Wert",
        "any valid value": "jeder gültige Wert",
    });
    hyperform.setLanguage("de");
    hyperform(window);
})

up.compiler('.js-phone-field', (element) => {
    new Cleave(element, {
        phone: true,
        phoneRegionCode: 'ch'
    });
})

up.compiler('.js-file-input', (element) => {
    FilePond.registerPlugin(FilePondPluginFileValidateType)
    FilePond.registerPlugin(FilePondPluginFileValidateSize)

    const pond = FilePond.create(element, {
        labelIdle: 'Datei(en) hierhin ziehen oder <span class="filepond--label-action">auswählen</span>',
        labelMaxFileSizeExceeded: 'Datei ist zu gross',
        labelMaxFileSize: 'Maximale Dateigrösse ist {filesize}',
        labelMaxTotalFileSizeExceeded: 'Maximale Dateigrösse überschritten',
        labelMaxTotalFileSize: 'Maximale Dateigrösse ist {filesize}',
        labelFileTypeNotAllowed: 'Dateityp nicht erlaubt',
        fileValidateTypeLabelExpectedTypes: 'Erlaubte Dateitypen sind {allButLastType} oder {lastType}',
        labelInvalidField: 'Feld enthält ungültige Dateien',
        labelFileWaitingForSize: 'Warte auf Dateigrösse',
        labelFileSizeNotAvailable: 'Dateigrösse nicht verfügbar',
        labelFileLoading: 'Lade Datei',
        labelFileLoadError: 'Fehler beim Laden der Datei',
        labelFileProcessing: 'Lade Datei hoch',
        labelFileProcessingComplete: 'Datei hochgeladen',
        labelFileProcessingAborted: 'Hochladen abgebrochen',
        labelFileProcessingError: 'Fehler beim Hochladen der Datei',
        labelFileProcessingRevertError: 'Fehler beim Zurücksetzen',
        labelFileRemoveError: 'Fehler beim Entfernen',
        labelTapToCancel: 'tippen um abzubrechen',
        labelTapToRetry: 'tippen um zu wiederholen',
        labelTapToUndo: 'tippen um rückgängig zu machen',
        labelButtonRemoveItem: 'Entfernen',
        labelButtonAbortItemLoad: 'Abbrechen',
        labelButtonRetryItemLoad: 'Wiederholen',
        labelButtonAbortItemProcessing: 'Abbrechen',
        labelButtonUndoItemProcessing: 'Rückgängig machen',
        labelButtonRetryItemProcessing: 'Wiederholen',
        labelButtonProcessItem: 'Hochladen',
        name: "uploads",
        required: true,
        allowMultiple: true,
        maxFileSize: '5MB',
        acceptedFileTypes: ['image/*', 'application/pdf'],
        storeAsFile: true,
    })
})
